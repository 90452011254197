import React, {Fragment, useEffect, useState} from "react";
import OperationPicture from "./OperationPicture";
import IbatApi from "../utils/IbatApi";

let api =new IbatApi()

export default function FamilyPicture({pictures,onChangePictureVisible,onCaroussel,index,depot}){

    const [name,setName] =useState()
    useEffect(()=>{

        !name && getTaken(pictures.find(elem=>elem.motherId===null).id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pictures])

    function getTaken(uuid) {
        depot &&
        api.getTaken(uuid)
            .then(data=> {
                console.log(data)
                let name = data.type.name ?? data.type;
                if (data.quantity && data.quantity>0){
                    name = name + " x" + data.quantity
                    if (data.unit) {
                        name = name + data.unit

                    }
                }

                data.type && setName(name )
            })
    }

    return(
        <div className={"bord1 family"} >
            {depot && name}
            {pictures.sort((p1,p2)=>p1.date-p2.date).map(p=>
                <Fragment key={p.id}>
                    <span className={"index"}>{depot ? name : index}</span>
                    <OperationPicture
                        filePath={p.filePath}
                                      id={p.id}
                                      visible={p.visible}
                                      layer={p.layer}
                                      url={p.url}
                                      visibleStyleClass={"hidden"}
                                      onRightClick={onChangePictureVisible}
                                      onClick={onCaroussel} />
                </Fragment>)}
        </div>
    )
}
