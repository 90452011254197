import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import IbatApi from "../utils/IbatApi";
import constants, {text} from "../components/util/constants";
import BillIcon from "../images/new-devis.png";
import {IconButton} from "@mui/material";
import DocumentLoader from "../components/util/DocumentLoader";

export default function EditEstimate({id,update, content}) {
    const {enqueueSnackbar} = useSnackbar();
    const [estimate, setEstimate] = useState(content ?? "");
    const [quotations, setQuotations] = useState();

    let api = new IbatApi();

    useEffect(() => {
        api.getInterventionQuotation(id)
            .then(r => r.length > 0 && setQuotations(r))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
    })

    function handleTime(e) {
        setEstimate(e.target.value);
    }
    function handleEnter(e){
        if (e.key === "Enter"){
            handleSave()
        }
    }

    function handleSave() {
        api.setEstimate(id, encodeURIComponent(estimate))
            .then(c => update())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED, {variant: "success"}))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
    }


    if (!api.hasRole(constants.PERMISSION_BILL)) {
        return <h3>{text.NO_RIGHTS_PROMPT}</h3>
    }
    return (<div className={"editIntervention flex-column primary-section"}>
            <div className={"img-container"}>
                <img alt={""} src={BillIcon}/>
            </div>
            {
                quotations &&
                <div className={"secondary-section"}>
                    <h2>Devis existants</h2>
                    {quotations?.map(q => <div className={"flex-row"}>
                        {q.odooPdfId && <DocumentLoader name={q.odooPdfId}/>}
                        <a rel={"noreferrer"} href={`/quotation/${q.id}`} target={"_blank"}>{q.odooRef}</a>
                    </div>)}
                </div>
            }
            <div className={"secondary-section"}>
                <h2>{text.ESTIMATE_NUMBER}</h2>
                <input type={"text"} placeholder={text.ESTIMATE_NUMBER} value={estimate} onChange={handleTime} onKeyDown={handleEnter}/>
                <IconButton className={"action-btn validation"} onClick={handleSave}>Enregistrer</IconButton>
            </div>
        </div>
    )
}